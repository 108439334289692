<div class="container-fluid">
  <ng-container [formGroup]="formGroup">
    <div class="row">
      <div class="col-md-3">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{'employee.department' | translate}}</mat-label>
          <mat-select #selDepartment formControlName="departments" multiple
                      (openedChange)="onAnySelectionChange($event)">
            <mat-option [value]="ALL_VALUE"
                        (click)="toggleAllSelection(selDepartment)">{{"generic.all-f" | translate}}</mat-option>
            <mat-option *ngFor="let department of departments"
                        [value]="department">
              {{department}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{'employee.employee' | translate}}</mat-label>
          <mat-select #selBadgeNumber formControlName="employeeBadgeNumbers" multiple
                      (openedChange)="onAnySelectionChange($event)">
            <mat-option [value]="ALL_VALUE"
                        (click)="toggleAllSelection(selBadgeNumber)">{{"generic.all-m" | translate}}</mat-option>
            <mat-option *ngFor="let employee of employees"
                        [value]="employee.badgeNumber">
              {{employee.fullName}} ({{employee.badgeNumber}})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{'generic.period' | translate}}</mat-label>
          <mat-date-range-input formGroupName="dateInRange" [rangePicker]="picker">
            <input matStartDate formControlName="startDate" placeholder="Start date" #startDate>
            <input matEndDate formControlName="endDate" placeholder="End date" #endDate>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker (closed)="onAnySelectionChange(false)"></mat-date-range-picker>
          <mat-error
            *ngIf="formGroup.get('dateInRange.startDate')?.hasError('matStartDateInvalid')">{{"leave.messages.invalidStartDate" | translate}}</mat-error>
          <mat-error
            *ngIf="formGroup.get('dateInRange.endDate')?.hasError('matEndDateInvalid')">{{"leave.messages.invalidEndDate" | translate}}</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-2">
        <mat-form-field appearance="fill" class="full-fill">
          <mat-label>{{'generic.type' | translate}}</mat-label>
          <mat-select formControlName="locations" multiple (openedChange)="onAnySelectionChange($event)">
            <mat-option *ngFor="let type of LocationType | keyvalue" [value]="type.value">
              {{"location.types." + type.key | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>
  </ng-container>
</div>
